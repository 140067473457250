$verifier-blue:				#3D58A6;
$verifier-blue-pale:		#5E7DBB;
$verifier-grey:				#373837;
$verifier-success:			#8EC549;
$verifier-blue-bg:			#D7E9F7;
$grey:						#999;
$grey-mid:					#bbb;
$grey-bg:					#e6e6e6;
$grey-bg-light:             #efefef;
$verifier-disabled-text:	$grey-mid;
$highlight:					#fff6c9;
$state-success-text:		#0d934b;
$state-warning-text:        rgb(237,114,5);
$state-warning-bg:          RGB(251, 227, 205);
$body-text-size:			18px;
$body-text-size-minus1:		$body-text-size - 1;
$standard-btn-text-size:	12px;
$sizing-multiplier:			0.85;
$progress-bar-bg:			$verifier-blue;
$header-nav-border-width:   4px;

// helper values
$helper-lg:                 30px;
$helper-md:                 20px;
$helper-sm:                 10px;
$helper-xs:                 5px;