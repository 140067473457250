@import "variables";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,900');
@import "~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss";

$font-size: $body-text-size;

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

body {
  background: white;
  color: $verifier-grey;
  font-size: $font-size;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;

  @include for-phone-only {
    font-size: $font-size * $sizing-multiplier;
  }
}

strong {
  font-weight: 500;
}

footer {
  padding: 2rem;
  color: $grey;

  & a { color: $grey; }
}

h1,
h3,
h2,
h4,
h5,
h6 {
  color: $verifier-blue;
  font-family: Lato, sans-serif;
  font-weight: 900;
  margin-top: $helper-lg;
  margin-bottom: $helper-md;
}

h1 {
  $h1-size: $font-size * 1.7;
  font-size: $h1-size;
  font-weight: 200;

  @include for-phone-only {
    font-size: $h1-size * $sizing-multiplier;
  }
  
  &.section-heading {
	  color: $verifier-grey;
	  font-weight: 100;
	  $section-heading-size: $h1-size * 0.9;
	  font-size: $section-heading-size;
	  margin-bottom: $helper-md;
	  
	  @include for-phone-only {
	    font-size: $section-heading-size * $sizing-multiplier;
	  }
  }
}

h2 {
  $h2-size: $font-size * 1.4;
  font-size: $h2-size;
  
  @include for-phone-only {
    font-size: $h2-size * $sizing-multiplier;
  }
}

h3 {
  $h3-size: $font-size * 1.3;
  font-size: $h3-size;
  font-weight: 500;
  color: $verifier-grey;
  
  @include for-phone-only {
    font-size: $h3-size * $sizing-multiplier;
  }
}

h4 {
  $h4-size: $font-size * 1.1;
  font-size: $h4-size;
  
  @include for-phone-only {
    font-size: $h4-size * $sizing-multiplier;
  }
}


.well {
  background: $grey-bg-light;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  padding: 24px;
  margin: $helper-md 0;

  @include for-phone-only {
    margin-top: 0;
    padding: 10px;
  }
}

.btn-primary {
  font-size: $font-size;
  background-color: $verifier-blue;
  border-color: $verifier-blue;

  &[disabled] {
    background-color: $grey;
    border-color: $grey;
  }
}

.verifier {
  &-header {
    background: $verifier-blue-bg;
    overflow: hidden;
    margin-bottom: 0;

    &:focus {
      outline: none;
    }

    &-logo {
      height: 100px;
      width: 100px;
      background: url(/img/verifier-money-pulse.png);
      background-size: cover;
      float: left;
      margin-top: $helper-md;
      margin-bottom: $helper-sm;
    }

    &-nav {
      border-bottom: $header-nav-border-width solid $verifier-blue;
    }
  }

  &-flex-column {
    display: flex;
    flex-direction: column;
  }
}

.popover-header {
  margin-top: 0;
}

.welcome-section {
  min-height: 70vh;
  margin-bottom: 50px;
}

.flex-element {
  display: flex;
  align-content: center;
  flex-flow: column wrap;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.question {
  min-height: 90vh;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  padding: $helper-md 0;

  &:nth-child(odd) {
    background: $grey-bg-light;

    .well {
      background: white;
    }
  }

  &-statement {
    border-left: 8px solid $grey-mid;
    padding-left: $helper-lg;
    margin: $helper-md 0;
    
    @include for-phone-only {
	    font-size: 1.25rem * $sizing-multiplier;
	    padding-left: $helper-sm;
	}
  }
}

.section-title{
  font-weight: bold;
  margin-left: 1rem;
}
.lead {
  color: $verifier-blue;
}

.icon-statement {
  color: $verifier-grey;
  margin-right: $helper-md;
  opacity: 0.3;

  @include for-phone-only {
    display: none;
  }
}

.form-check {
  margin: $helper-sm 0 $helper-sm $helper-lg;
  
  @include for-phone-only {
    margin-left: $helper-xs;
  }

  &-input {
    position: absolute;
    margin-right: $helper-sm;
    top: 2px;
  }
}

.in-page-nav {
  background: none;
  font-size: 0.9rem;
  color: $grey;
  border: none;
  outline: none;
  padding: 5px 5px 5px 0;
  margin-right: $helper-sm;

  &-next {
    color :  $verifier-blue !important;
    font-weight: bold;
  }
}

.additional-resources-link {
  background: none;
  font-size: 1rem;
  color: $grey;
  border: none;
  outline: none;
  padding: 5px 5px 5px 0;
  margin-right: $helper-sm;
}


.questionnaire::-webkit-scrollbar {
  display: none;
}

.results {
  .question {
    min-height: 0;
  }

  &-value {
    color: $verifier-blue;
    font-weight: bold;
  }
}

.progress {
  height: $helper-lg;
  background: white;

  .bg-warning {
    background-color: #ff7b07 !important;
  }

  .bg-info {
    background-color: #ffc107 !important;
  }

  &-caption {
    line-height: 1.2;
    text-align: center;
  }
}


.faq-popup{
  text-align: left !important;
  width: 64rem !important;
  font-size: 0.9rem;

  h4 {
    margin-top: $helper-md;
    margin-bottom: $helper-xs;
  }

}

.slider-container {
	margin: $helper-md $helper-xs 0 $helper-xs;
	background: $grey-bg-light;
	border-radius: $helper-xs;
	padding-top: $helper-xs;
}

.other-error {
  border : 1px solid red;
}


